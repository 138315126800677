import axios from "axios";
import * as Sentry from "@sentry/react";
import Auth from "../models/Auth";
import { sentryCustomCaptureException } from "../utils/custom-capture-exception";

class Axios {
  static getUnauthenticatedInstance() {
    // Initialize instance
    if (!this._unauthenticatedAxios) {
      const _unauthenticatedAxios = axios.create({
        headers: {
          "Content-Type": "application/json",
        },
        timeout: 1000 * 30, // 30 sec
      });
      _unauthenticatedAxios.interceptors.response.use(
        (response) => response,
        (error) => {
          sentryCustomCaptureException(error);
          return Promise.reject(error);
        }
      );
      this._unauthenticatedAxios = _unauthenticatedAxios;
    }
    return this._unauthenticatedAxios;
  }

  static getInstance() {
    // Initialize instance
    if (!this._axios) {
      const _axios = axios.create({
        headers: {
          "Content-Type": "application/json",
        },
        timeout: 1000 * 30, // 30 sec
      });
      _axios.interceptors.request.use((config) => {
        const abortController = new AbortController();

        if (Auth.data) {
          const auth_data = localStorage.getItem("auth_data");
          try {
            const authData = JSON.parse(
              Buffer.from(auth_data, "base64").toString("utf-8")
            );
            config.headers[
              "Authorization"
            ] = `Bearer ${authData["accessToken"]}`;
          } catch (e) {
            const userData = Auth.tryGetUserDataFromLocalStorage();
            if (userData && userData.userData) {
              Sentry.setUser({
                id: userData.userData.id,
                email: userData.userData.email,
                username: userData.userData.username,
              });
            }
            sentryCustomCaptureException(
              new Error(
                "Error while parsing auth data from local storage (during Axios request). User will be redirected to login page."
              )
            );
            abortController.abort();

            Auth.logout();
            window.dispatchEvent(new Event("app:invalidAuthData"));
          }
        } else {
          abortController.abort();
        }

        return {
          ...config,
          signal: abortController.signal,
        };
      });

      _axios.interceptors.response.use(
        (response) => response,
        (error) => {
          if (error.response && error.response.status === 401) {
            sentryCustomCaptureException(
              new Error(
                "API call returns 401. User will be redirected to login page."
              )
            );

            Auth.logout();
            window.dispatchEvent(new Event("app:apiCallReturns401"));
          } else {
            if (axios.isCancel(error)) {
              return Promise.reject(error);
            }
            sentryCustomCaptureException(error);
            return Promise.reject(error);
          }
        }
      );

      this._axios = _axios;
    }
    return this._axios;
  }
}

export default Axios;
